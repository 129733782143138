import db from '../../db.json';

export const AdvertisementBar = () => {
    const whatsAppObject = db.linkTrees.find(link => link.text === 'WhatsApp');

    if (!whatsAppObject) {
        return null;
    }

    return (
        <section className="bg-charcoal text-white text-center p-1">
            <a href={whatsAppObject.url}>
                We invite resellers and consignment partners to collaborate with us.
            </a>
        </section>
    )
}