import { AdvertisementBar } from "../AdvertisementBar/AdvertisementBar";
import { Footer } from "../footer/Footer";
import { LinkTree } from "../linktree/LinkTree";

export const Home = () => (
    <div>
        <AdvertisementBar />
        <LinkTree />
        <Footer />
    </div>
)