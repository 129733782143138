import db from '../../db.json';

export const Resellers = () => (
    <>
        <h2 className="text-3xl text-bold text-center mb-3">DM our resellers</h2>
        {
            db.resellers.map(reseller => reseller.isActive && (
                <a 
                        className="text-xl mt-2 border w-full text-center p-2 max-w-[968px] cursor-pointer 
                            hover:bg-charcoal hover:text-white active:bg-charcoal active:text-white"
                        href={reseller.url}
                        key={reseller.id}
                    >
                        <span>{reseller.areaName}</span>
                    </a>
            ))
        }
    </>
)