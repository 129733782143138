import { AdvertisementBar } from "../AdvertisementBar/AdvertisementBar";
import { Footer } from "../footer/Footer";
import { Logo } from "../Logo/Logo";

export const NoMatch = () => (
    <div>
        <AdvertisementBar />
        <div className="flex flex-col items-center p-2 min-h-screen">
            <Logo />
            <section>
                Oops. You're in the wrong page
            </section>
        </div>
        <Footer />
    </div>
)